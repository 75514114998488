import { createTheme } from '@mui/material/styles';

const caption = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '0.75rem',
  fontSize: '0.75rem',
  color: '#848484',
};

const subtitle1 = {
  fontFamily: 'Roboto',
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.125rem',
  color: '#000000',
};

const subtitle2 = {
  fontFamily: 'Roboto',
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.125rem',
  color: '#848484',
};

const h1 = {
  color: '#000000',
  fontFamily: 'Roboto',
  fontWeight: 600,
  fontSize: '1.5rem',
  textAlign: 'center' as const,
};

const h2 = {
  fontWeight: 500,
  fontSize: '1.25rem',
  textAlign: 'center' as const,
  marginBottom: '2rem',
};

export const h3 = {
  fontWeight: 500,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  color: '#000000',
  textAlign: 'left' as const,
  marginBottom: '0.5rem',
};

export const h4 = {
  fontWeight: 600,
  fontSize: '1rem',
  color: '#000000',
  marginBottom: '1.4375rem',
};

export const h5 = {
  fontWeight: 500,
  fontSize: '0.875rem',
  color: '#000000',
  marginBottom: '1rem',
};

export const h6 = {
  fontWeight: 600,
  fontSize: '0.625rem',
  color: '#000000',
};

export const body1 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.25rem',
  fontSize: '1rem',
  textAlign: 'left' as const,
  marginBottom: '1.25rem',
  color: '#D9D6CD',
};

export const body2 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.25rem',
  fontSize: '1rem',
  marginBottom: '1.25rem',
  color: '#393636',
};

export const fontFamily = `Roboto`;

export const button = {
  width: '100%',
  height: '3rem',
  background: '#3276b5',
  textTransform: 'none' as const,
  '&:disabled': {
    background: '#D9D6CD',
    color: '#ffffff',
    width: '100%',
  },
  root: {
    '&:hover': {
      backgroundColor: '#33a6d7',
      color: '#ffffff',
    },
  },
};

export const basePalette = {
  primary: {
    main: '#3276b5',
    light: '#3276b5',
    dark: '#3276b5',
    contrastText: '#3276b5',
  },
  secondary: {
    main: '#393636',
    light: '#393636',
    dark: '#F3F3F3',
    contrastText: '#FDFDFD',
  },
  tertiary: {
    main: '#202020',
  },
  background: {
    paper: '#ffffff',
    default: '#FDFDFD',
  },
  action: {
    active: '#848484',
    focus: '#234666',
  },
  success: {
    main: '#71D77B',
    light: '#A1C8ED',
    dark: '#71D77B',
  },
  warning: {
    main: '#ff0000',
    light: '#D85058',
  },
  gradient: {
    main: 'linear-gradient(104.37deg, #33a6d7 7.55%, #3276b5 95.18%)',
    light: 'linear-gradient(104.37deg, #bababa 7.55%, #848484 95.18%)',
    dark: 'linear-gradient(180deg, #98d0e8 0%, #33a6d7 100%, #c4c4c4 100%)',
  },
  title: {
    primary: '#3276b5',
    secondary: '#234666',
    black: '#000000',
    dark: '#393636',
  },
  icon: {
    main: '#3276b5',
    light: '#848484',
    dark: '#393636',
  },
  divider: '#A1C8ED',
  demiplane: {
    main: '#00ABFF',
    light: '#58A3EA',
    dark: '#2D6AA2',
    contrastText: '#FFFFFF',
  },
  purchase: {
    main: '#58E490',
    light: '#90ffc1',
    dark: '#04b162',
    contrastText: '#FFFFFF',
  },
  cancel: {
    main: '#687079',
    light: '#969ea8',
    dark: '#3d454d',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#1F344D',
    secondary: '#BFBFBF',
  },
  white: {
    main: '#FFFFFF',
  },
  gold: {
    gold1: '#F1CE86',
    gold2: '#FFF2D8',
  },
  grey: {
    400: '#D1D1D1',
    600: '#444242',
    700: '#3B3E45',
    800: '#323232',
  },
  demi: {
    white: '#FFFFFF',
    blue: '#00ABFF',
    green: '#58E490',
    yellow: '#FFD84D',
    red: '#FF5C5C',
  },
};

const typography = {
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  h5: h5,
  h6: h6,
  body1: body1,
  body2: body2,
  caption: caption,
  subtitle1,
  subtitle2,
  button,
  title: {
    fontFamily: 'GoodOT',
  },
  common: {
    fontFamily: 'Nunito',
  },
  special: {
    fontFamily: 'Taroca',
  },
  default: {
    fontFamily: 'Roboto',
  },
};

const overrides = {
  MuiButton: {
    styleOverrides: {
      text: {
        color: '#ffffff',
      },
      root: {
        '&:hover': {
          backgroundColor: '#D9D6CD',
          color: '#ffffff',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        marginBottom: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '0.9375rem',
        color: '#848484',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        marginBottom: 0,

        '&.MuiFilledInput-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',

          '&.Mui-focused': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
          },

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
          },

          '&.MuiFilledInput-underline': {
            '&:before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
              left: 0,
              bottom: 0,
              content: '" "',
              position: 'absolute',
              right: 0,
              transition:
                'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              pointerEvents: 'none',
              color: '#1F344D',
            },
            '&:after': {
              borderBottom: '2px solid rgb(50, 118, 181)',
              left: 0,
              bottom: 0,
              content: '" "',
              position: 'absolute',
              right: 0,
              transform: 'scaleX(0)',
              transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
              pointerEvents: 'none',
            },
          },
        },

        '&.MuiOutlinedInput-root': {
          '& > .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
          },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#D9D6CD',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        color: '#000000',
      },
    },
  },
};

export const baseTheme = createTheme({
  palette: {
    mode: 'dark',
    ...basePalette,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1600,
      xl: 1900,
    },
  },
  typography: typography,
  components: {
    ...overrides,
  },
});
