import React, { useEffect, useMemo } from 'react';
import { addPageStyles } from '../../lib/addPageStyles';
import { removePageStyles } from '../../lib/removePageStyles';
import { useRouter } from 'next/router';

interface StylesheetLoaderProps {
  pageProps: Record<string, any>;
  children: React.ReactNode;
}

export const StylesheetLoader = (props: StylesheetLoaderProps) => {
  const {
    pathname: currentRoutePath,
    query: { nexusSlug, sourceSlug },
  } = useRouter();
  const { pageProps, children } = props;
  const characterData = pageProps.characterData;
  const characterVersion = useMemo(
    () => characterData?.version ?? 1,
    [characterData]
  );

  useEffect(() => {
    const beforeBodyClass = document.body.className;
    const stylesheets = [
      `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/navigation.css`,
    ];

    if (window.location.href.includes('/dops')) {
      stylesheets.push(`${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/dops.css`);
    }

    if (window.location.href.includes('/characters')) {
      stylesheets.push(
        `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/characters.css`
      );
    }

    if (
      window.location.href.includes('/groups') ||
      window.location.href.includes('/matchmaking')
    ) {
      stylesheets.push(`${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/groups.css`);
    }

    if (window.location.href.includes('/games')) {
      stylesheets.push(`${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/games.css`);
    }

    if (nexusSlug) {
      stylesheets.push(
        `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/${nexusSlug}/nexus.css`
      );
      document.body.className = `${nexusSlug}-styles`;

      if (
        nexusSlug === 'pathfinder2e' &&
        window.location.href.includes('harrow-deck')
      ) {
        stylesheets.push(
          `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/${nexusSlug}/sources/harrow-deck.css`
        );
      }

      if (characterData && window.location.href.includes('character-sheet')) {
        stylesheets.push(
          `${
            process.env.NEXT_PUBLIC_STYLES_CDN_URL
          }/${nexusSlug}/character_sheet${
            characterVersion > 1 ? `_v${characterVersion}` : ''
          }.css`
        );
      }

      if (characterData && window.location.href.includes('character-builder')) {
        stylesheets.push(
          `${
            process.env.NEXT_PUBLIC_STYLES_CDN_URL
          }/${nexusSlug}/character_builder${
            characterVersion > 1 ? `_v${characterVersion}` : ''
          }.css`
        );
      }

      if (characterData) {
        stylesheets.push(
          `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/${nexusSlug}/character_tools.css`
        );
      }

      if (sourceSlug) {
        stylesheets.push(
          `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/${nexusSlug}/sources/${sourceSlug}.css`
        );
      }
    }

    addPageStyles(stylesheets);

    return () => {
      removePageStyles(stylesheets);
      document.body.className = beforeBodyClass;
    };
  }, [
    characterData,
    characterVersion,
    currentRoutePath,
    nexusSlug,
    sourceSlug,
  ]);

  return <>{children}</>;
};
