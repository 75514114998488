/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import 'simplebar/dist/simplebar.css';
import './style.scss';
import '../src/components/Drawers/GameTypesDrawer/styles.css';
import '../src/components/Drawers/AdventuringPlatformsDrawer/styles.css';
import '../src/components/Drawers/ConfigurePortalDrawer/components/LookingForPlayers/styles.css';
import '../src/components/Drawers/NewPortalDrawer/styles.css';
import '@assets/main.css';
import '@assets/chrome-bug.css';
import 'keen-slider/keen-slider.min.css';
import '../styles/search-results.css';
import '../styles/nexus-font.css';
import '../styles/override-hacks.css';
import '../styles/picker-override.css';
import { EmotionCache } from '@emotion/cache';
import { createEmotionCache } from 'src/lib/createEmotionCache';
import { NextPage } from 'next';
import { WithUrqlProps } from 'next-urql';
import Script from 'next/script';
import { useReportWebVitals } from 'next/web-vitals';
import { CacheProvider } from '@emotion/react';
import { baseTheme } from 'src/theme/baseTheme';
import { ThemeProvider } from '@mui/material/styles';
import { StylesheetLoader } from 'Components/StylesheetLoader';

declare global {
  interface Window {
    __URQL_DATA__: any;
  }
}

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps, WithUrqlProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    urqlClient,
    resetUrqlClient,
  } = props;

  useReportWebVitals((metric) => {
    if (process.env.NEXT_PUBLIC_LOG_WEB_VITALS === 'true') {
      console.debug(metric);
    }
  });

  const getLayout = (Component as any).getLayout || ((page: NextPage) => page);

  // need to replace route listener functionality
  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <link rel='shortcut icon' href='/favicon.ico' />
        <link rel='apple-touch-icon' href='/favicon.ico'></link>
        <link rel='icon' href='/favicon.ico'></link>

        <link
          rel='preconnect'
          href={`https://${process.env.NEXT_PUBLIC_SWELL_STORE_ID}.swell.store`}
        ></link>
        <link rel='preconnect' href='https://connect.facebook.net'></link>
        <link
          rel='preconnect'
          href={`${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT}`}
        ></link>
        <link
          rel='preconnect'
          href={`${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}`}
        ></link>
        <link
          rel='apple-touch-icon'
          href='/images/production/6b42dcfe-9c15-4ab2-0ce3-87a756c00367.webPlatform.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href='/images/production/7d27ee2c-0d30-4f08-64f4-d11b862f5817.webPlatform.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/images/production/1e4909c6-8f19-d63f-1f5c-176eaef689e5.webPlatform.png'
        />

        <meta name='apple-mobile-web-app-capable' content='yes' />
        <link
          href='/images/apple_splash_2048.png'
          sizes='2048x2732'
          rel='apple-touch-startup-image'
        />
        <link
          href='/images/apple_splash_1668.png'
          sizes='1668x2224'
          rel='apple-touch-startup-image'
        />
        <link
          href='/images/apple_splash_1536.png'
          sizes='1536x2048'
          rel='apple-touch-startup-image'
        />
        <link
          href='/images/apple_splash_1242.png'
          sizes='1242x2208'
          rel='apple-touch-startup-image'
        />
        <link
          href='/images/apple_splash_1125.png'
          sizes='1125x2436'
          rel='apple-touch-startup-image'
        />
        <link
          href='/images/apple_splash_640.png'
          sizes='640x1136'
          rel='apple-touch-startup-image'
        />

        <meta
          name='viewport'
          content='viewport-fit=cover, width=device-width, initial-scale=1.0'
        />
        <meta name='theme-color' content='#000000' />
        {/*Notice the use of %PUBLIC_URL% in the tags above.*/}
        {/*It will be replaced with the URL of the `public` folder during the build.*/}
        {/*Only files inside the `public` folder can be referenced from the HTML.*/}

        {/*Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will*/}
        {/*work correctly both with client-side routing and a non-root public URL.*/}
        {/*Learn how to configure a non-root public URL by running `npm run build`.*/}
        {/*We load the Stripe library directly from their hosted location instead of*/}
        {/*via NPM to maintain PCI compliance. See https://stripe.com/docs/recipes/elements-react.*/}
      </Head>

      <Script
        src='https://8x8.vc/libs/external_api.min.js'
        strategy='afterInteractive'
      />
      <Script id='google-tag-manager'>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});const f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
      `}
      </Script>
      <StylesheetLoader pageProps={pageProps}>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={baseTheme}>
            {getLayout(
              <Component
                {...pageProps}
                urqlClient={urqlClient}
                resetUrqlClient={resetUrqlClient}
              />,
              pageProps
            )}
          </ThemeProvider>
        </CacheProvider>
      </StylesheetLoader>
    </>
  );
};

export default MyApp;
