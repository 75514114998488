export const removePageStyles = (styleUrls: string[]) => {
  const head = document.head || document.getElementsByTagName('head')[0];

  for (const [index] of styleUrls.entries()) {
    const style = document.getElementById(`custom-style-${index}`);

    if (!!style) {
      head.removeChild(style);
    }
  }
};
