export const addPageStyles = (styleUrls: string[]) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  let stylesheetCount = 0;

  const onStylesheetLoaded = () => {
    stylesheetCount += 1;
    if (stylesheetCount === styleUrls.length) {
      // console.log('Stylesheets fully loaded...', { styleUrls });
    }
  };

  for (const [index, url] of styleUrls.entries()) {
    const style = document.createElement('link');
    style.id = `custom-style-${index}`;
    style.rel = 'stylesheet';
    style.href = url;
    style.type = 'text/css';
    style.onload = onStylesheetLoaded;
    head.appendChild(style);
  }
};
